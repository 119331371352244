<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col xl="12" lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                      <b-row>
                        <b-col xs="12" sm="12" md="6">
                            <ValidationProvider name="Visit Date" vid="visit_date" rules="required">
                            <b-form-group
                                label-for="visit_date"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('teaGardenBtriService.visit_date')}}<span class="text-danger">*</span>
                                </template>
                                <date-picker
                                id="date"
                                v-model="form.visit_date"
                                class="form-control"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </date-picker>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Note (En)" vid="visit_note_en" rules="required">
                            <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="visit_note_en">
                              <template v-slot:label>
                                {{ $t('globalTrans.note')}} {{ $t('globalTrans.en')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="visit_note_en"
                              v-model="form.visit_note_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Note (Bn)" vid="visit_note_bn" rules="required">
                            <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="visit_note_bn">
                              <template v-slot:label>
                                {{ $t('globalTrans.note')}} {{ $t('globalTrans.bn')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="visit_note_bn"
                              v-model="form.visit_note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.submit') }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-visit-garden-confirm')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { onlineAdvisoryVisitConfirm } from '../../../api/routes'

export default {
    name: 'OnlineVisitConfirm',
    props: ['id'],
    created () {
      if (this.id) {
        const tmp = this.getInfo()
        this.form = tmp
      }
    },
    watch: {},
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        currentLocale () {
          return this.$i18n.locale
        }
    },
    data () {
      return {
          saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
          form: {
          },
          loader: false
      }
    },
    methods: {
      onChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.form.attachment = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.form.attachment = ''
        }
      },
      getInfo () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      async submit () {
          this.loader = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadinState = { loading: false, listReload: true }
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${onlineAdvisoryVisitConfirm}/${this.id}`, this.form)
          this.$store.dispatch('mutateCommonProperties', loadinState)
          if (result.success) {
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$bvModal.hide('modal-visit-garden-confirm')
          } else {
              this.$refs.form.setErrors(result.errors)
          }
          this.loader = false
      }
    }
}
</script>
